@use "styles/Variables.scss";

.about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--second-color-dark);
    padding-top: 120px;
    margin-bottom: 120px;
    .about-label {
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
        font-weight: 700;
        font-size: 42px;
        text-transform: uppercase;
        @media screen and (max-width: 960px) {
            font-size: 32px;
        }
        .pre-label {
            margin-right: 20px;
            color: var(--color-dark);
        }
        .after-label {
            color: var(--accent-color);
        }
    }
    .about-content {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: var(--color-light);
        box-shadow: var(--main-shadow);
        z-index: 1;
        .about-content-width {
            display: flex;
            flex-direction: column;
            width: var(--desktop-max-width);
            padding: 70px 0;
            @media screen and (max-width: 1290px) {
                width: 960px;
            }
            @media screen and (max-width: 986px) {
                width: 660px;
                flex-wrap: wrap;
                justify-content: end;
            }
            .about-text-wrapper {
                color: var(--color-dark);
                text-align: center;
                margin-bottom: 100px;
                @media screen and (max-width: 986px) {
                    margin-bottom: 40px;
                    padding: 0 10px 0 10px;
                }
                .title-text {
                    font-weight: 500;
                    font-size: 42px;
                    font-weight: 700;
                    line-height: 46px;
                    margin-bottom: 30px;
                    @media screen and (max-width: 986px) {
                        font-size: 32px;
                        line-height: 36px;
                    }
                }
                .main-tetx {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
    }
}
