.job-card-wrapper {
  width: 100%;
  max-width: 900px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 20px 30px;
  margin-bottom: 45px;
  overflow-x: auto;
  color: var(--color-dark);
  transition: all 300ms ease;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .job-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    margin-bottom: 25px;
    padding-left: 0;
    .description {
      display: flex;
      overflow: hidden;
      max-height: 25px;
      transition: max-height 200ms ease;
      white-space: pre-wrap;
      word-wrap: break-word;
      .description-label {
        margin-right: 10px;
        font-weight: 600;
      }
    }
    .description-more {
      max-height: 500px;
      overflow: auto;
      margin-bottom: 20px;
      @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
      }
    }
    .read-more-button {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 10px;
      transition: all 200ms ease;
      margin-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .readmore-icon {
        margin-top: 20px;
        width: 20px;
        height: 20px;
        fill: var(--color-dark);
      }
    }
    .job-card-position,
    .job-card-salary,
    .job-card-location {
      display: flex;
      margin-bottom: 20px;
      p {
        margin-right: 10px;
        font-weight: 600;
      }
    }
  }
  .button-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    .fill-out-form-button {
      text-align: center;
      justify-self: center;
    }
    .manage-buttons {
      display: flex;
      .delete-job-button,
      .edit-job-button {
        width: 35px;
        height: 35px;
        margin-right: 15px;
        justify-self: center;
        padding: 0;
        border: none;
        background: none;
        transition: scale 200ms ease;
        cursor: pointer;
        &:hover {
          scale: (1.1);
        }
      }
    }
  }
}
