@use "styles/Variables.scss";

.footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  background-color: var(--main-color);
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    color: var(--color-light);
    width: 100%;
    .footer-logo {
      margin-bottom: 20px;
    }
    .footer-copyright {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 960px) {
        flex-direction: column;
      }
      .footer-left,
      .copyright,
      .footer-right {
        width: 33%;
        text-align: center;
      }
      .footer-right {
        font-size: 12px;
        a {
          color: var(--color-light);
        }
        @media screen and (max-width: 960px) {
          margin-top: 10px;
        }
      }
    }
  }
}
