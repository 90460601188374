.training-main-title-content-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px 0;
    ul {
        text-align: left;
        margin-left: 40px;
    }
}
.training-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    img {
        width: fit-content;
        height: fit-content;
        border-radius: 30px;
        margin: 50px 0 200px 0;
        box-shadow: 0px 10px 70px -35px rgba(0, 0, 0, 0.75);
    }
    .left-illustration {
        transform: translateY(-40px);
        z-index: 2;
        @media screen and (max-width: 769px) {
            transform: translateY(0px);
            margin-bottom: 40px;
        }
    }
    .right-illustration {
        transform: translateX(-25px);
        z-index: 1;
        @media screen and (max-width: 769px) {
            transform: translateX(0px);
            margin-bottom: 80px;
        }
    }
    @media screen and (max-width: 769px) {
        flex-direction: column;
        img {
            margin: 0;
            width: 100vw;
        }
    }
}
