@use 'styles/Variables.scss';

.partnership-form-wrapper {
  padding-top: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .partnership-backplate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .partnership-image {
      width: 100%;
      -webkit-mask-image: linear-gradient(to top, transparent, black);
      mask-image: linear-gradient(to top, transparent, black);
      height: 650px;
      aspect-ratio: 16 * 9;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }
  .partnership-title {
    color: var(--color-dark);
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
  }
  .partnership-sub-title {
    margin-bottom: 40px;
    max-width: 550px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-dark);
    padding: 0 25px;
  }
  .partnership-form {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 15px;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .partnership-form-iframe {
      width: 600px;
      height: 900px;
      transform: translateY(-100px);
      border: 0px;
      border-radius: 15px;
      margin-bottom: 60px;
      box-shadow: 0 -20px 25px rgba($color: #000000, $alpha: 0.05), 0 -15px 15px rgba($color: #000000, $alpha: 0.01);
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }
}
