:root {
    --main-color: rgba(126, 176, 193, 1);
    --second-color-dark: rgba(46, 70, 78, 1);
    --accent-color: rgba(254, 91, 96, 1);
    --color-dark: rgba(45, 70, 78, 1);
    --color-light: rgba(255, 255, 255, 1);
    --main-shadow: 0px 16px 53px -6px rgba(0, 0, 0, 0.11);
    --header-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    --desktop-max-width: 1274px;
}
