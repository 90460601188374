@use "Styles/variables.scss";

.custom-button-header-wrapper {
  .button {
    margin-right: 10px;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: 500;
    color: var(--color-dark);
    cursor: pointer;
    transition: all 300ms ease-in-out;
    position: relative;
    background: none;
    border: none;
    font-weight: 600;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      width: 100%;
      border-radius: 5px;
      background-color: var(--main-color);
      transition: all 300ms ease-in-out;
    }
    &:hover {
      position: relative;
      color: var(--color-light);
      &::before {
        content: "";
        height: 100%;
        box-shadow: var(--main-shadow);
        z-index: -1;
      }
    }
  }
}
