@use "styles/Variables.scss";

.header-wrapper {
    position: fixed;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    background-color: var(--second-color-dark);
    top: 0;
    box-shadow: var(--header-shadow);
    z-index: 9;
    transition: all 300ms ease-in-out;
    &.active {
        height: 60px;
        .logo {
            svg {
                height: 40px;
            }
        }
    }
    .header-content-wrapper {
        width: var(--desktop-max-width);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            max-width: fit-content;
            svg {
                transition: all 300ms ease-in-out;
            }
        }
        @media screen and (max-width: 1330px) {
            padding: 0 10px 0 10px;
        }
        @media screen and (max-width: 986px) {
            width: 100%;
        }
    }
}
