.login-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    h1 {
        margin-bottom: 45px;
    }
    .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
            width: 250px;
            margin-bottom: 25px;
            border-radius: 15px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 20px;
            height: 40px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            &:focus {
                outline: none;
            }
        }

        button {
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            height: 40px;
            transition: all 200ms ease-in-out;
            margin-bottom: 10px;
            cursor: pointer;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            &:hover {
                scale: 1.02;
                box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075),
                    0 16px 16px hsl(0deg 0% 0% / 0.075);
            }
        }
        .error-message {
            font-size: 12px;
            color: red;
        }
    }
    .logged-in-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin-bottom: 45px;
        }
        .lougout-button {
            width: 200px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            height: 40px;
            transition: all 200ms ease-in-out;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            cursor: pointer;
            &:hover {
                scale: 1.02;
                box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075),
                    0 16px 16px hsl(0deg 0% 0% / 0.075);
            }
        }
    }
}
