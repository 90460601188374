@use "styles/Variables.scss";

.wrapper-home {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    // background-color: var(--main-color);
    // background-image: url("../../../assets/images/Illustration1.jpg");
    background-size: cover;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #7eb0c1 4.17%, rgba(177, 191, 196, 0.51087) 61.46%, rgba(217, 217, 217, 0) 100%);
    }
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        transform: scaleX(-1);
    }

    .home-content-wrapper {
        width: var(--desktop-max-width);
        margin-top: 120px;
        padding: 20px;
        color: var(--color-light);
        z-index: 0;

        .pre-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            margin: 90px 0;

            @media screen and (max-width: 896px) {
                font-size: 20px;
            }
        }

        // .company-neme {
        //     font-weight: 700;
        //     font-size: 96px;
        //     line-height: 92.5%;
        //     margin-bottom: 40px;

        //     @media screen and (max-width: 896px) {
        //         font-size: 10vw;
        //         margin-bottom: 45px;
        //     }
        // }

        .slogan {
            font-weight: 500;
            font-size: 42px;
            font-weight: 700;
            margin-bottom: 250px;
            @media screen and (max-width: 896px) {
                font-size: 10vw;
                margin-bottom: 40px;
            }
        }

        .arrow-down {
            position: absolute;
            bottom: 25px;
            left: calc(50% - 17.5px);
            cursor: pointer;

            svg {
                fill: var(--color-light);
                animation: bounce 0.6s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
            }
        }
    }
}

.modal-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    align-items: center;
    height: fit-content;
    width: 100%;
    .modal-close-button {
        position: absolute;
        right: 50px;
        top: 50px;
        z-index: 999;
        width: 44px;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
        font-size: 44px;
        transition: scale 200ms ease;
        &:hover {
            scale: (1.1);
        }
    }
    .employee-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }
    iframe {
        width: 100%;
        height: 100vh;
        border: none;
    }
    @media screen and (max-width: 506px) {
        max-width: 340px;
    }

    // .requirements {
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     height: 100%;
    //     padding-left: 10px;
    //     @media screen and (max-width: 1170px) {
    //         height: fit-content;
    //         padding-left: 0px;
    //     }
    //     .requirements-wrapper {
    //         width: 320px;
    //         margin-right: 25px;
    //         color: var(--color-dark);
    //         border: solid 1px rgba(0, 0, 0, 0.2);
    //         border-radius: 5px;
    //         padding: 15px;
    //         transition: all 150ms ease-in-out;
    //         @media screen and (max-width: 1170px) {
    //             margin-bottom: 25px;
    //         }
    //         @media screen and (max-width: 506px) {
    //             margin-right: 0;
    //         }
    //         .requirements-title {
    //             text-decoration: underline;
    //             text-align: center;
    //             margin-bottom: 25px;
    //         }
    //         .requirements-content {
    //             line-height: 1.7;
    //             font-size: 14px;
    //         }
    //         &:hover {
    //             scale: 1.02;
    //             box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    //         }
    //     }
    // }
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 10px, 0);
    }
}
