@use "styles/Variables.scss";

.links-wrapper {
    display: flex;
    align-items: center;
    .link {
        &.active {
            .button {
                color: var(--color-light);
                background-color: var(--main-color);
                box-shadow: var(--main-shadow);
                border-radius: 5px;
                &:hover {
                    &::before {
                        content: "";
                        height: 0;
                        box-shadow: none;
                        z-index: -1;
                    }
                }
                &:last-child {
                    margin-right: 30px;
                }
            }
        }
    }
    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid var(--color-dark);
        color: var(--color-dark);
        margin-right: 25px;
        font-weight: 700;
        cursor: pointer;
        transition: all 300ms ease-in-out;
        &:hover {
            scale: 1.1;
        }
    }
}
.light-dark-switcher {
    display: flex;
    align-items: center;
    width: 24px;
    margin-right: 25px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
        rotate: (22deg);
    }
    @media screen and (max-width: 960px) {
        margin-right: 0px;
        margin-bottom: 40px;
    }
}
.language-switch {
    color: var(--color-dark);
    font-size: 14px;
    cursor: pointer;
    div {
        display: flex;
        align-items: center;
        transition: all 200ms ease-in-out;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        img {
            width: 30px;
        }
        &:hover {
            scale: 1.05;
        }
    }
    span {
        margin-left: 10px;
    }
}
.translate-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    svg {
        fill: var(--color-dark);
        width: 25px;
        height: 25px;
    }
}
