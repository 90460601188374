@use 'styles/Variables.scss';

@mixin training-box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.training-box-wrapper {
  display: flex;
  position: relative;
  margin-top: 120px;
  height: calc(100vh - 120px);
  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
  .training-box-tower,
  .training-box-matts {
    width: 50%;
    position: relative;
    height: calc(100vh - 120px);
    p {
      font-size: 30px;
      color: rgba(255, 255, 255, 1);
      z-index: 2;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      pointer-events: none;
      border: 1px solid var(--color-light);
      padding: 20px 40px;
      border-radius: 15px;
      backdrop-filter: blur(5px);
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  @media screen and (min-width: 768px) {
    .training-box-tower {
      @include training-box-center;
      background: url('../../assets/images/tower.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      clip-path: polygon(0 0, 100% 0, 76.8% 100%, 0% 100%);
      width: 51.8%;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 300ms ease-in-out;
      }
      &:hover::before {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
      }
    }
    .training-box-matts {
      @include training-box-center;
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      background: url('../../assets/images/matts.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
      z-index: 1;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 300ms ease-in-out;
      }
      &:hover::before {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 769px) {
    .training-box-tower {
      @include training-box-center;
      background: url('../../assets/images/tower.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 300ms ease-in-out;
      }
      &:hover::before {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
      }
    }
    .training-box-matts {
      @include training-box-center;
      background: url('../../assets/images/fiber.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
      width: 100%;
      border-top: 3px solid rgba(255, 255, 255, 1);
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 300ms ease-in-out;
      }
      &:hover::before {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
      }
    }
  }
}
