@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-light);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.custom-react-cookie-banner {
    backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0.1) !important;
    bottom: calc(50% - 125px);
    height: 200px !important;
    left: calc(50% - 150px);
    position: fixed !important;
    width: 300px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--color-light);
    .button-close {
        position: unset !important;
        font-weight: 600;
        font-size: 24px;
    }
}

.MuiModal-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    .child {
        position: absolute;
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(15px);
        pointer-events: none;
        z-index: -1;
    }
    .margin-bottom {
        margin-bottom: 20px;
    }
    .MuiBackdrop-root {
        background-color: var(--color-light);
        opacity: 0.7 !important;
    }
}

.MuiTooltip-tooltipPlacementBottom {
    color: var(--color-dark) !important;
    background-color: var(--color-light) !important;
    box-shadow: 0px 10px 20px (rgba(0, 0, 0, 0.3));
    padding: 15px !important;
    .MuiTooltip-arrow {
        color: var(--color-light);
    }
}

*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--main-color);
}

.MuiTabScrollButton-root {
    svg {
        fill: var(--color-dark);
    }
}

.MuiInputBase-root {
    color: var(--color-dark) !important;
}
