.specialize-card-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 685px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .specialize-card-wrapper {
    min-width: 320px;
    max-width: 320px;
    height: 512px;
    border-radius: 5px;
    box-shadow: var(--main-shadow);
    justify-self: center;
    margin: 25px 25px;
    flex-basis: calc(100% / 3);
    transition: all 300ms ease-in-out;
    &:hover {
      scale: 1.05;
      transform: scaleY(1.05);
    }
    .specialize-card-top {
      position: relative;
      height: 195px;
      img {
        width: 100%;
        height: 195px;
        border-radius: 5px;
        object-fit: cover;
      }
      .specialize-card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 195px;
        background: linear-gradient(360deg, rgb(23, 35, 39) 0%, rgba(56, 64, 94, 0) 100%);
      }
      .specialize-card-title {
        position: absolute;
        bottom: 20px;
        font-weight: 600;
        font-size: 20px;
        left: 10%;
        color: var(--color-light);
      }
    }
    .specialize-card-bottom {
      // overflow-y: auto;
      height: fit-content;
      padding: 30px;
      color: var(--color-dark);
      ul {
        // list-style: none;
        padding: 0 10px;
        li {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
