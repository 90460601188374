.page-not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  .page-not-found-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-dark);
    p {
      margin-bottom: 20px;
      line-height: 60px;
    }
  }
}
