@use "styles/Variables.scss";

.contacts-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--second-color-dark);
    margin-bottom: 120px;
    .contacts-box {
        display: flex;
        justify-content: space-between;
        width: var(--desktop-max-width);
        @media screen and (max-width: 1290px) {
            width: 960px;
        }
        @media screen and (max-width: 986px) {
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
        }
        .side-left {
            @media screen and (max-width: 986px) {
                margin-bottom: 40px;
                margin-right: 40px;
            }
            @media screen and (max-width: 506px) {
                margin-bottom: 40px;
                padding: 0 10px 0 10px;
                margin-right: 0px;
            }
            .contacts-label {
                display: flex;
                margin-bottom: 24px;
                font-weight: 700;
                font-size: 42px;
                text-transform: uppercase;
                @media screen and (max-width: 960px) {
                    font-size: 32px;
                }
                .pre-label {
                    margin-right: 20px;
                    color: var(--color-dark);
                }
                .after-label {
                    color: var(--accent-color);
                }
            }
            .contacts-content {
                .address,
                .phone,
                .email {
                    display: flex;
                    a {
                        color: var(--color-dark);
                    }
                    .contact-icon {
                        margin-right: 10px;
                        svg {
                            height: 12px;
                            fill: var(--accent-color);
                        }
                    }
                }
                .work-time {
                    margin: 24px 0;
                    color: var(--color-dark);
                }
            }
            .follow-us {
                margin-bottom: 24;
            }
            .social-icons {
                svg {
                    margin-right: 15px;
                    transition: all 300ms ease-in-out;
                    &:hover {
                        scale: 1.1;
                        filter: drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.3));
                    }
                }
            }
        }
    }
    .map {
        margin-top: 100px;
        width: 100%;
        height: 600px;
        border: 0;
    }
    .office-image {
        position: absolute;
        overflow: hidden;
        width: 425px;
        height: 295px;
        bottom: 120px;
        right: 15%;
        border-radius: 5px;
        border: solid 2px var(--color-light);
        @media screen and (max-width: 506px) {
            width: 225px;
            height: 195px;
        }
        box-shadow: 0px 10px 70px -35px rgba(0, 0, 0, 0.75);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
